export class CheckinStatusLabelVm {
  constructor(private status: string) {}

  get blobColour() {
    return `${this.status.replaceAll('_', '-')}`
  }

  get text() {
    switch (this.status) {
      case 'exceeding':
        return 'Exceeding'
      case 'meeting':
        return 'Meeting'
      case 'not_checked_in':
        return 'Not Checked-In'
      case 'working_towards':
        return 'Working Towards'
    }
  }
}
