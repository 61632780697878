import * as React from 'react'
import cn from 'classnames'
import { CheckinStatusLabelVm } from './checkin-status-label-vm'

export type CheckinStatus =
  | 'exceeding'
  | 'meeting'
  | 'not_checked_in'
  | 'working_towards'

export type CheckinStatusLabelProps = {
  status: CheckinStatus
}

export const CheckinStatusLabel: React.VFC<CheckinStatusLabelProps> = (
  props
) => {
  const { status } = props

  const vm = new CheckinStatusLabelVm(status)

  return (
    <div className="bg-white border-b border-l border-r border-t border-gray-200 border-solid rounded w-max">
      <span
        className={cn(
          vm.blobColour,
          'align-self-start level-text-label py-0.5 whitespace-nowrap blob'
        )}
      >
        {vm.text}
      </span>
    </div>
  )
}
